import { idtype } from "querypieces";
import bodyCopy from "blocks/body-copy/query";
import item from "blocks/item/query";

export default `
...on pageBuilder_bulletList_BlockType {
	${idtype}
	heading
	colors
	children {
		${bodyCopy}
		${item}
	}
}
`