import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { pageEntryLink } from "querypieces";

const footer = gql`
  query {
    cta: globalSet(handle: "footerCTA") {
      links: pageEntry {
        ...on page_page_Entry {
          ${pageEntryLink}
        }
      }
    }
  }
`;

export default () => {
  return useQuery(footer);
};
