import React from "react"
import { Link } from "react-router-dom"
import { OutboundLink } from "react-ga"
import styles from "./news.module.scss"
import Img from "components/image/image"

const News = ({ id, typeHandle, news, ...rest }) => {
  return news && news.length ? <div className={styles.list}>
    {news.map(item => <NewsItem key={item.id} {...item} />)}
  </div> : <NewsItem {...rest} />
}

const NewsItem = ({
  copy,
  externalUrl,
  image,
  publishDate,
  publisherName,
  slug,
  title,
  typeHandle,
  ...rest
}) => {
  return typeHandle === "news" ? <Link to={`/updates/${slug}`} className={styles.element}>
    <Card
      title={title}
      image={image}
      publishDate={publishDate}
      publisherName={publisherName}
      copy={copy}
    />
  </Link> : <OutboundLink
    to={externalUrl}
    target="_blank"
    rel="noopener noreferrer"
    eventLabel={externalUrl}
    className={styles.element}>
      <Card
        title={title}
        image={image}
        publishDate={publishDate}
        publisherName={publisherName}
        copy={copy}
      />
    </OutboundLink>
}

const Card = ({
  image,
  title,
  copy,
  publisherName,
  publishDate
}) => {
  let date = new Date(publishDate)
  return <>
    {image ? <Img className={styles.image} {...image[0]} /> : null}
    <div className={styles.content}>
      <h5 className={styles.heading}>{title}</h5>
      <p className={styles.copy}>{copy}</p>
      <div className={styles.meta}>
        <span>{publisherName}</span>
        <span>{date.toLocaleDateString()}</span>
      </div>
    </div>
  </>
}

export default News
