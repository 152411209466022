export const idtype = `
  id
  typeHandle
`;

export const pageEntryLink = `
  id
  uri
  title
  ...on page_page_Entry {
    color: accentColor
  }
`;

export const link = `
  url
  text
  type
  element {
    id
    uri
    title
    ...on page_page_Entry {
      color: accentColor
    }
  }
`;

export const linkTo = `
linkTo {
  ${link}
}
`;


export const image = `image {
  id
  extension
  url
  ...on images_Asset {
    title
    alt: copy
    optimizedImage {
      src
      srcset
      srcsetWebp
      placeholderBox
      width: originalImageWidth
      height: originalImageHeight
    }

    imageFull {
      src
      srcset
      srcsetWebp
      placeholderBox
      width: originalImageWidth
      height: originalImageHeight
    }
    originalImageSize {
      src
      srcset
      srcsetWebp
      placeholderBox
      width: originalImageWidth
      height: originalImageHeight
    }
  }
}`;

// export const image = `image {
//   ...on images_Asset {
//     id
//     alt
//     title
//     extension
//     url
//   }
// }`;

// optimizedImage {
//   src
//   srcset
//   srcsetWebp
//   placeholderBox
//   width: originalImageWidth
//   height: originalImageHeight
// }
// optimized11 {
//   src
//   srcset
//   srcsetWebp
//   placeholderBox
//   width: originalImageWidth
//   height: originalImageHeight
// }
// optimized21 {
//   src
//   srcset
//   srcsetWebp
//   placeholderBox
//   width: originalImageWidth
//   height: originalImageHeight
// }
// optimized43 {
//   src
//   srcset
//   srcsetWebp
//   placeholderBox
//   width: originalImageWidth
//   height: originalImageHeight
// }
// optimized45 {
//   src
//   srcset
//   srcsetWebp
//   placeholderBox
//   width: originalImageWidth
//   height: originalImageHeight
// }


export const resources = `
...on resources_resources_Entry {
  ${idtype}
  title
  copy: longCopy
  colors
  ${image}
  hasVid
  links: desktopNav {
    ...on desktopNav_item_BlockType {
      id
      item {
        ${link}
      }
    }
  } 
}
`;

export const news = `
  ${idtype}
  slug
  title
  copy
  ${image}
  publisherName: heading
  publishDate
`;

export const updates = `
...on updates_update_Entry {
  ${news}
}
`;

export const updatesExt = `
...on updates_externalUpdate_Entry {
  ${news}
  externalUrl
}
`;