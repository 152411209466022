import React from "react"
import { Link } from "react-router-dom"
import useFooter from "./useFooter";
import SVG from 'react-inlinesvg'
import logo from "svgs/logo.svg";
import RichText from "components/rich-text";
import styles from "./footer.module.scss"

const Footer = () => {
  const { data } = useFooter();
  return <footer className={styles.element}>
    <div className={styles.blocks}>
      <Link to="/">
        <SVG src={logo} preProcessor={code => code.replace(/fill=".*?"/g, 'fill="#fff"')} />
      </Link>
      {data && <>
        {data.footer.footerBuilder.map(block => {
          return <div key={block.id}>
            {block.typeHandle === "address" ? <p className={styles.content}>{block.block}</p> : <RichText text={block.block} className={styles.content} />}
          </div>
        })}
      </>}
      {data && data.footer.embed ? <RichText text={data.footer.embed} className={styles.form} /> : ""}
    </div>
    {data && data.footer.copyright ? <RichText text={data.footer.copyright} className={styles.copyright} /> : null}
  </footer>
}

export default Footer
