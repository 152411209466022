import ctaBlock from "./cta-block/query";
import image from "./image/query";
import testimonial from "./testimonial/query";
// import video from "./video/query";
import bodyCopy from "./body-copy/query";
import heading from "./heading/query";
import bulletList from "./bullet-list/query";
import headingBody from "./heading-body/query";
import newsBlock from "./news-block/query";
import news from "./news/query";
import resourceBlock from "./resource-block/query";
import resources from "./resources/query";
import internalCtaBlock from "./internal-cta-block/query";
import pageLink from "./page-link/query";
import accordionContent from "./accordion-content/query";
import accordionItem from "./accordion-item/query";
import accordionList from "./accordion-list/query";
import locationMap from "./location-map/query";
import sideBySide from "./side-by-side/query";
import getInformedQuestions from "./get-informed-questions/query";
import item from "./item/query";
/* import-cursor */

const blocks = [
  // embed,
  accordionContent,
  accordionItem,
  accordionList,
  bodyCopy,
  bulletList,
  ctaBlock,
  heading,
  headingBody,
  image,
  internalCtaBlock,
  locationMap,
  news,
  newsBlock,
  pageLink,
  resourceBlock,
  resources,
  sideBySide,
  testimonial,
  getInformedQuestions,
  item,
  /* object-cursor */
];

export default blocks;
