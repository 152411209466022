import { idtype } from "querypieces";
import news from "blocks/news/query";

export default `
...on pageBuilder_newsBlock_BlockType {
	${idtype}
	heading
	copy
	children {
		${news}
	}
}
`