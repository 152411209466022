import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const query = gql`
  query {
    alert: globalSet(handle: "alertBar") {
      ...on alertBar_GlobalSet {
        id
        name
        isLive
        richText
      }
    }
  }
`;

export default () => {
  return useQuery(query);
};
