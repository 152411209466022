import React from "react"
import styles from "./resource-block.module.scss"
import Resources from "blocks/resources"

const ResourceBlock = ({
  heading,
  copy,
  children
}) => {
 return <section className={styles.element}>
   <h3 className={styles.heading}>{heading}</h3>
   <h4 className={styles.subheading}>{copy}</h4>
   {children.map(resource => <Resources key={resource.id} {...resource} />)}
 </section>
}

export default ResourceBlock