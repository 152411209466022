import React from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import ReactGA from "react-ga";
import outbound from "svgs/outbound.svg";
import download from "svgs/download.svg";

import styles from "./link.module.scss";

const Link = ({ url, text, element, children, __typename, type, className, ...rest }) => {
  switch (type) {
    case "entry":
      return element && (
        <RouterLink
          to={`/${element.uri === "__home__" ? "" : element.uri}`}
          className={`${styles.element} ${className}`}
          {...rest} 
        >
          {children || text}
        </RouterLink>
      );
    
    case "nav":
      return element && (
        <NavLink
          to={`/${element.uri === "__home__" ? "" : element.uri}`}
          className={`${styles.element} ${className}`}
          {...rest} 
        >
          {children || text}
        </NavLink>
      );

    case "asset":
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.element} ${className} has-arrow`}
          {...rest}
          onClick={() => {
            ReactGA.event({
              category: "Download",
              action: "Download",
              label: element.title || url,
            });
          }}
        >
          {children || text}
          <SVG src={download} />
        </a>
      );

    default:
      return (
        <ReactGA.OutboundLink
          to={url}
          target="_blank"
          rel="noopener noreferrer"
          eventLabel={url}
          className={`${styles.element} ${className} has-arrow`}
          {...rest}
        >
          {children || text}
          <SVG src={outbound} />
        </ReactGA.OutboundLink>
      );
  }
};

export default Link;
