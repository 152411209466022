import React from "react";
import styles from "./image.module.scss"

const Image = ({
  url,
  optimizedImage,
  extension,
  className = "",
  alt,
  title,
  ...rest
}) => {
  const isSvg = extension === "svg" || extension === "gif";
  const { placeholderBox, src, srcset, srcsetWebp } = optimizedImage ? optimizedImage : {};

  if (isSvg) {
    return (
      <img
        data-src={url}
        src={placeholderBox}
        className="lazyload"
        alt={alt || title}
        width={rest.width}
        height={rest.height}
      />
    );
  }
  return (
    <picture className={`${styles.element} ${className}`}>
      <Source srcset={srcset} srcsetWebp={!isSvg && srcsetWebp} />
      <Img
        {...rest}
        placeholderBox={placeholderBox}
        src={src}
        srcset={!isSvg && srcset}
        alt={alt || title}
      />
    </picture>
  );
};

export const ImageOriginal = ({
  url,
  originalImageSize,
  extension,
  className = "",
  alt,
  title,
  ...rest
}) => {
  const isSvg = extension === "svg" || extension === "gif";
  const { placeholderBox, src, srcset, srcsetWebp } = originalImageSize ? originalImageSize : {};

  if (isSvg) {
    return (
      <img
        data-src={url}
        src={placeholderBox}
        className="lazyload"
        alt={alt || title}
      />
    );
  }
  return (
    <picture className={`${styles.element} ${className}`}>
      <Source srcset={srcset} srcsetWebp={!isSvg && srcsetWebp} />
      <Img
        {...rest}
        placeholderBox={placeholderBox}
        src={src}
        srcset={!isSvg && srcset}
        alt={alt || title}
      />
    </picture>
  );
};

export const ImageFull = ({
  url,
  imageFull,
  extension,
  className = "",
  alt,
  title,
  ...rest
}) => {
  const isSvg = extension === "svg" || extension === "gif";
  const { placeholderBox, src, srcset, srcsetWebp } = imageFull ? imageFull : {};

  if (isSvg) {
    return (
      <img
        data-src={url}
        src={placeholderBox}
        className="lazyload"
        alt={alt || title}
      />
    );
  }
  return (
    <picture className={`${styles.element} ${className}`}>
      <Source srcset={srcset} srcsetWebp={!isSvg && srcsetWebp} />
      <Img
        {...rest}
        placeholderBox={placeholderBox}
        src={src}
        srcset={!isSvg && srcset}
        alt={alt || title}
      />
    </picture>
  );
};

export const Source = ({ srcset, srcsetWebp, media }) => {
  return (
    <>
      {srcsetWebp && (
        <source data-srcset={srcsetWebp} media={media} type={"image/webp"} />
      )}
      <source data-srcset={srcset} media={media} />
    </>
  );
};

export const Img = ({
  src,
  alt,
  srcset,
  placeholderBox,
  title,
  width,
  height,
  __typename,
  ...rest
}) => {
  return (
    <img
      alt={alt || title}
      className="lazyload"
      src={placeholderBox}
      data-src={src || `//placehold.it/${width}x${height}`}
      data-srcset={srcset}
      sizes="100vw" //why?
      width={width}
      height={height}
    />
  );
};

export default Image;
