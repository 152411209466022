import { idtype } from "querypieces";
import accordionContent from "blocks/accordion-content/query";

export default `
...on pageBuilder_accordionList_BlockType {
	${idtype}
	heading
	accentColor
	content: children {
		${accordionContent}
	}
}
`