import React from "react";
import heading from "./heading";
import ctaBlock from "./cta-block";
import bodyCopy from "./body-copy";
import testimonial from "./testimonial";
import image from "./image";
import bulletList from "./bullet-list";
import headingBody from "./heading-body";
import newsBlock from "./news-block";
import news from "./news";
import resourceBlock from "./resource-block";
import resources from "./resources";
import internalCtaBlock from "./internal-cta-block";
import pageLink from "./page-link";
import richText from "./rich-text";
import accordionContent from "./accordion-content";
import accordionItem from "./accordion-item";
import accordionList from "./accordion-list";
import locationMap from "./location-map";
import sideBySide from "./side-by-side";
import getInformedQuestions from "./get-informed-questions";
import item from "./item";
/* import-cursor */

const components = {
  heading,
  ctaBlock,
  bodyCopy,
  testimonial,
  image,
  bulletList,
  headingBody,
  newsBlock,
  news,
  resourceBlock,
  resources,
  internalCtaBlock,
  pageLink,
  richText,
  accordionContent,
  accordionItem,
  accordionList,
  locationMap,
  sideBySide,
  getInformedQuestions,
  item,
  /* object-cursor */
};

const Blocks = ({ blocks, entry, className }) => {
  return blocks.map((block) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} className={className} />;
  });
};
export default Blocks;
