import { idtype, updates, updatesExt } from "querypieces";

export default `
...on pageBuilder_news_BlockType {
	${idtype}
	news {
		${updates}
		${updatesExt}
	}
}
`