import React from "react"
import {Link} from "react-router-dom";
import SVG from "react-inlinesvg";
import arrow from "svgs/arrow.svg";
import styles from "./page-link.module.scss";

const PageLink = ({
  colors,
  linkTo
}) => {
  let bgColor = linkTo.element.color ? linkTo.element.color : colors

  return <Link to={`/${linkTo.element.uri}`} style={{
    backgroundColor: `var(--${bgColor})`
  }}
  className={styles.element}>
    <span className={styles.text}>{linkTo.text}</span>
    <SVG src={arrow} />
  </Link>
}

export default PageLink