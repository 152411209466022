import React from "react"
// import styles from "./app.module.scss"
import Page from "components/page";
import Update from "components/update";
import Nav from "components/nav";
import Footer from "components/footer";
import AlertBar from "components/alert-bar";
import GoogleTranslate from "components/google-translate";
import { Switch, Route } from "react-router-dom";
import NavProvider from "nav-context";
import "scss/main.scss";


const App = () => {
  return <main>
    <GoogleTranslate />
    <NavProvider>
      <AlertBar />
      <Nav />
    </NavProvider>

    <Switch>
      <Route path="/updates/:slug" component={Update} />
      <Route path="/:slug?" component={Page} />
    </Switch>

    <Footer />
  </main>
}

export default App