import React from "react"
import styles from "./heading-body.module.scss"
import RichText from "components/rich-text"

const HeadingBody = ({heading,copy,colors,blockStyle,accordion,className=""}) => {
  let small = blockStyle === "small"
  return <section className={`${styles.element} ${className+colors} stack-pad`}>
    <h2 className={small ? `${styles.headingsmall} bar-${colors}` : styles.heading}>{heading}</h2>
    {copy ? <RichText className={small ? styles.body : styles.subheading} text={copy} accordion={accordion} /> : null}
  </section>
}

export default HeadingBody