import React from "react"
import News from "blocks/news"
import styles from "./news-block.module.scss"

const NewsBlock = ({
  heading,
  copy,
  className,
  children
}) => {
 return <section className={`${styles.element} ${className}`}>
   <h3 className={styles.heading}>{heading}</h3>
   {copy ? <h4 className={styles.subheading}>{copy}</h4> : null}
   <div className={styles.list}>
    {children.map(update => {
      return update.news.length ? update.news.map(item =>
        <News key={item.id} {...item} />
      ) : <News key={update.id} {...update} />
      }
    )}
   </div>
 </section>
}

export default NewsBlock