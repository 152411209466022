import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import cookie from "react-cookies";
import { useSpring, a } from "react-spring"
import styles from "./language-nav.module.scss"

const langs = [
  { code: "en", name: "English" },
  { code: "es", name: "Español" }, // spanish
  { code: "fr", name: "Français" }, // french
  { code: "zh-CN", name: "中文" }, // chinese
  { code: "ru", name: "Русский язык" }, //russian
  { code: "bn", name: "বাংলা" }, // bengali
  { code: "ar", name: "العربية" }, // arabic
  { code: "ko", name: "한국어" }, // korean
  { code: "pl", name: "polski" }, // polish
  { code: "uz", name: "Ўзбек тили" }, // uzbek
  { code: "ur", name: "اُردُو" }, //urddu
  { code: "ht", name: "Kreyòl ayisyen" } // hatian creole
]

export default ({ open, setOpen }) => {
  const [language, setLanguage] = useState(cookie.load("lang") ? cookie.load("lang") : "en")
  const resetLang = () => {
    let iframe = document.getElementById(":1.container")
    let close = iframe ? iframe.contentDocument.getElementById(":1.close") : null

    if (close) {
      if ("createEvent" in document) {
        let evt = document.createEvent("HTMLEvents")
        evt.initEvent("click", false, true)
        close.dispatchEvent(evt)
      } else {
        close.fireEvent("onclick")
      }
    }
    return
  }

  const style = useSpring({
    display: open ? "block" : "none"
  })

  useEffect(() => {
    let googleTag = document.getElementById("google_translate_element")
    let select = googleTag ? googleTag.querySelector("select") : null

    if (language === "en") {
      resetLang()
    } else {
      document.documentElement.lang = `${language}-x-mtfrom-en`
      let linkRel = document.createElement("link")
      linkRel.rel = "alternate machine-translated-from"
      linkRel.hrefLang = "en"
      linkRel.href = process.env.PUBLIC_SITE_URL
      document.head.appendChild(linkRel)

      if (select) {
        select.value = language

        if ("createEvent" in document) {
          let evt = document.createEvent("HTMLEvents")
          evt.initEvent("change", false, true)
          select.dispatchEvent(evt)
        } else {
          select.fireEvent("onchange")
        }
      }
    }
  }, [language])

  return <a.ul className={styles.element} style={style}>
    {langs.map(lang => <li key={lang.code}>
      <Link
        to="#"
        className={`${styles.link}${language === lang.code ? " active" : ""}`}
        title={`Switch to ${lang.name}`}
        onClick={() => {
          if (lang.code === language) return
          setLanguage(lang.code)
          setOpen(false)
          cookie.save("lang", lang.code, { path: "/" })
        }}
      >{lang.name}</Link>
    </li>
    )}
  </a.ul>
}
