import { gql } from "apollo-boost";
import resourceBuilder from "components/resource-builder/query";
import { idtype, news, updatesExt } from "querypieces";

export default gql`
  query($slug: String) {
    entry(section: "updates", slug: [$slug]) {
      ${idtype}
      ${updatesExt}
      ...on updates_update_Entry {
        ${news}
        resourceBuilder {
          ${resourceBuilder}
        }
      }
    }
  }
`;
