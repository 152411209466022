import React, { Component } from "react"

class GoogleTranslate extends Component {
  constructor(props) {
    super(props)
    this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this)
  }
  googleTranslateElementInit() {
    new window.google.translate.TranslateElement({
      pageLanguage: "en",
      includedLanguages: "es,fr,zh-CN,ru,bn,ar,ko,pl,uz,ur,ht",
      layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
      autoDisplay: false,
      multilanguagePage: true,
      gaTrack: true,
      gaId: "UA-108073420-1",
      key: process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY
    }, 'google_translate_element')
  }

  componentDidMount() {
    let addScript = document.createElement("script");
    addScript.setAttribute("src", `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`);
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = this.googleTranslateElementInit;
  }

  render() {
    return <div id="google_translate_element"></div>
  }
}

export default GoogleTranslate;
