import React from "react"
import useFooter from "./useFooter";
import {Link} from "react-router-dom"
import SVG from 'react-inlinesvg'
import arrow from "svgs/arrow.svg";
import styles from "./footer-cta.module.scss"

const FooterCta = ({current}) => {
  const {data} = useFooter();
  return data && data.cta ? <aside className={styles.footer}>
    <ul className={styles.element}>
      {data.cta.links.map(link => {
        return current !== link.id ? <li 
          key={link.id} 
          className={styles.item}
          style={{backgroundColor: `var(--${link.color})`}}>
          <Link to={link.uri} className={styles.link}>
            <span>{link.title}</span>
            <SVG src={arrow} />
          </Link>
        </li> : null
      })}
    </ul>
  </aside> : null
}

export default FooterCta