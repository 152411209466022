import React from "react"
import styles from "./side-by-side.module.scss"
import Blocks from "blocks/blocks"

const SideBySide = ({ children, className }) => {
  return <section className={styles.element}>
    <Blocks blocks={children} className={`${className} side-by-side`} />
  </section>
}

export default SideBySide