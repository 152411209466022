import React from "react"
import AccordionContent from "blocks/accordion-content"
import Accordion from "components/accordion"
import styles from "./accordion-list.module.scss"

const AccordionList = ({
  heading,
  accentColor,
  content
}) => {
  return <div className={styles.element}>
    <Accordion title={heading} color={accentColor}>
      <AccordionContent blocks={content} />
    </Accordion>
 </div>
}

export default AccordionList
