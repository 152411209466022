import React from "react"
import PageLink from "blocks/page-link";
import styles from "./internal-cta-block.module.scss"

const InternalCtaBlock = ({children}) => {
  return <ul className={styles.element}>
    {children.map(link => (
      <li key={link.id} className={styles.item}>
        <PageLink {...link} />
      </li>)
    )}
 </ul>
}

export default InternalCtaBlock