import { useEffect, useState } from "react";

export const useCheckMobileScreen = () => {
  const client = typeof window !== "undefined"
  const [width, setWidth] = useState(client ? window.innerWidth : 1024);
  const handleWindowSizeChange = () => {
    setWidth(client ? window.innerWidth : width);
  }

  useEffect(() => {
    if (!client) return
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  });

  return (width < 1024);
}
