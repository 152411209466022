import lazySizes from "lazysizes";
import React from "react";
import { render, hydrate } from "react-dom";
import App from "components/app";
import { BrowserRouter } from "react-router-dom";
import ScrollMemory from 'react-router-scroll-memory';

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "fragmentTypes.json";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import "./index.css";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

export const client = new ApolloClient({
  uri: "/api",
  headers: {
    "X-Craft-Token": window.location.search.match(/\btoken=([^&]+)/)
      ? window.location.search.match(/\btoken=([^&]+)/)[1]
      : ""
  },
  cache
});

const renderMethod = !!module.hot ? render : hydrate;

lazySizes.init();

ReactGA.initialize('UA-108073420-3');
ReactGA.pageview(window.location.pathname + window.location.search);
TagManager.initialize({ gtmId: 'GTM-WD9CL2H' })

renderMethod(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <ScrollMemory />
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
