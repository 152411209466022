import React from "react"
import { Link } from "react-router-dom"
import SVG from 'react-inlinesvg'
import bar from "svgs/bar.svg";
import FooterCta from "components/footer-cta";
import styles from "./error-page.module.scss"

const ErrorPage = ({type}) => {
 return <article>
   <section className={styles.element}>
    {type === "loading" ? <h2>Loading...</h2> : <h1>{type}</h1>}
    <SVG src={bar} />
    {type !== "loading" ? <Error type={type} /> : null}
   </section>
   <FooterCta />
 </article>
}

const Error = (type) => (
  <div className={styles.copy}>
    <p>{type === "404" ? "This page does not exist." : "Something went wrong."}</p>
    <p>
      <Link className={styles.link} to="/">Click here</Link> to return to main site.
    </p>
  </div>
)

export default ErrorPage