import { useQuery } from "@apollo/react-hooks";
import query from "./query";

const useUpdate = ({ slug }) => {
  return useQuery(query, {
    variables: {
      slug,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
  });
};

export default useUpdate;
