import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { idtype, image } from "querypieces";

const footer = gql`
  query {
    footer: globalSet(handle: "footer") {
      ... on footer_GlobalSet {
        footerBuilder {
          ...on footerBuilder_copy_BlockType {
            ${idtype}
            block
          }
          ...on footerBuilder_address_BlockType {
            ${idtype}
            block
          }
          ...on footerBuilder_socialLink_BlockType {
            ${idtype}
            icon {
              id
              ${image}
            }
            linkUrl
          }
        }
        copyright: richTextSimple
        embed
      }
    }
  }
`;

export default () => {
  return useQuery(footer);
};
