import { idtype } from "querypieces";
import resourceBuilder from "components/resource-builder/query"

export default `
...on pageBuilder_getInformedQuestions_BlockType {
	${idtype}
	getInformedQuestions {
		...on getInformedQuestions_question_Entry {
			${idtype}
			title
			colors
			children {
				...on getInformedQuestions_getInformedQuestions_Entry {
					${idtype}
					title
					resourceBuilder {
						${resourceBuilder}
					}
				}
			}
		}
	}
}
`