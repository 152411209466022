import { idtype, image, updatesExt, updates } from "querypieces";


const heading = `...on resourceBuilder_heading_BlockType {
  ${idtype}
  heading
  colors
}`

export const bodyCopy = `...on resourceBuilder_bodyCopy_BlockType {
  ${idtype}
  richText
}`

export const imageBlock = `...on resourceBuilder_image_BlockType {
  ${idtype}
  ${image}
  colors
}`

const sideBySide = `...on resourceBuilder_sideBySide_BlockType {
  ${idtype}
  children {
    ${imageBlock}
    ${bodyCopy}
  }
}`

const testimonial = `...on resourceBuilder_testimonial_BlockType {
  ${idtype}
  heading
	body: richTextSimple
	attribution: copy
	accentColor

}`

const news = `...on resourceBuilder_news_BlockType {
  news {
    ${updates}
    ${updatesExt}
  }
}`

const newsBlock = `...on resourceBuilder_newsBlock_BlockType {
  ${idtype}
  heading
  children {
    ${news}
  }
}`

const item = `...on resourceBuilder_item_BlockType {
  ${idtype}
  richText: richTextSimple
}`

const bulletList = `...on resourceBuilder_bulletList_BlockType {
  ${idtype}
  heading
  copy
  children {
    ${item}
  }
}`

const resourceBuilder = [
  heading,
  bodyCopy,
  imageBlock,
  sideBySide,
  testimonial,
  newsBlock,
  bulletList,
];

export default resourceBuilder