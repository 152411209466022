import React, { useState, useRef } from "react"
import { a } from "react-spring";
import Accordion from "components/accordion"
import Blocks from "blocks/blocks"
import styles from "./get-informed-questions.module.scss"

import { useCheckMobileScreen } from "helpers"

const GetInformedQuestions = ({
  getInformedQuestions
}) => {
  const [open, setOpen] = useState(null);

  return <div className={styles.element}>
    {getInformedQuestions.map(question => <Accordion key={question.id} title={question.title} color={question.colors} update={open}>
      <ul className={styles.grid}>
        {question.children.map(block => <Block
          key={block.id}
          color={question.colors}
          setOpen={setOpen}
          open={open}
          {...block}
        />)}
      </ul>
    </Accordion>)}
  </div>
}

const closed = {
  opacity: 0
}
const open = {
  opacity: 1
}

const Block = ({
  id,
  color,
  title,
  resourceBuilder,
  open,
  setOpen
}) => {
  let active = open === id
  const mobile = useCheckMobileScreen()
  const ref = useRef()

  return <a.li className={`block-${color}`} style={active && !mobile ? {
    gridColumnEnd: 3,
    gridColumnStart: 1
  } : {}}>
    <h4
      ref={ref}
      className={`${styles.blockname}${active ? " open" : ""}`}
      onClick={() => {
        setOpen((curr) => curr !== id ? id : null)
        if (ref && ref.current) {
          let top = window.pageYOffset + ref.current.getBoundingClientRect().top - 200
          window.scrollTo(0, top)
        }
      }}
    >{title}</h4>
    {active ? <a.div className={`${styles.content} open`}>
      <Blocks blocks={resourceBuilder} className={"accordion nested"} />
    </a.div> : null}
  </a.li>
}

export default GetInformedQuestions