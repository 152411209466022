import React from "react"
import styles from "./testimonial.module.scss"
import RichText from "components/rich-text"

const Testimonial = ({
  heading,
  body,
  attribution,
  accentColor
}) => {
  return <div className={styles.element}>
    <h3 className={styles.heading}>{heading}</h3>
    <RichText text={body} className={styles.body} />
    <aside className={styles.accent} style={{
      backgroundColor: `var(--${accentColor})`
    }}/>
    <h4 className={styles.attr}>{attribution}</h4>
  </div>
}

export default Testimonial