import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { link } from "querypieces";

const query = gql`
query {
  nav: globalSet(handle: "nav") {
    ... on nav_GlobalSet {
      desktopNav {
        ...on desktopNav_item_BlockType {
          id
          item {
            ${link}
          }
        }
      }
    }
  }
}
`;

export default () => {
  return useQuery(query);
};
