import React from "react"
import styles from "./hero.module.scss"
import RichText from "components/rich-text"
import Image from "components/image"

const Hero = ({
  heading,
  copy,
  image
}) => {
  return <header className={styles.element}>
    <div className={styles.content}>
      <h1 className={styles.heading}>{heading}</h1>
      {copy ? <RichText text={copy} className={styles.subheading} /> : null}
    </div>
    {image ? <Image {...image[0]} key={image} /> : null}
  </header>
}

export default Hero