import { idtype } from "querypieces";

export default `
...on pageBuilder_headingBody_BlockType {
	${idtype}
	heading
	copy: richTextSimple
	colors
	blockStyle
	accordion: hasVid
}
`