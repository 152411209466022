import { idtype } from "querypieces";

export default `
...on pageBuilder_testimonial_BlockType {
	${idtype}
	heading
	body: richText
	attribution: copy
	accentColor
}
`