import { idtype, image, linkTo } from "querypieces";

export default `
...on pageBuilder_ctaBlock_BlockType {
	${idtype}
	colors
	heading
	copy
	${linkTo}
	${image}
}
`