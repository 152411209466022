import { idtype } from "querypieces";
import resources from "blocks/resources/query";

export default `
...on pageBuilder_resourceBlock_BlockType {
	${idtype}
	heading
	copy
	children {
		${resources}
	}
}
`