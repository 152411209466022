import { gql } from "apollo-boost";
import blocks from "blocks/query";
import { image, idtype } from "querypieces";
import resourceBuilder from "components/resource-builder/query";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ${idtype}
      ...on page_page_Entry {
        heroHead: longCopy
        headSubhead: richTextSimple
        heroImage: ${image}
        pageBuilder(level:1) {
          ${idtype}
          ${blocks}
        }
      }  
      ...on page_simplePage_Entry {
        title
        heading
        pageBuilder: resourceBuilder {
          ${resourceBuilder}
        }
      }      
    }
  }
`;
