import React from "react"
import styles from "./rich-text.module.scss"

import ReactHtmlParser from "react-html-parser";
import Accordion from "components/accordion"

const RichText = ({ text, className = "", accordion, ...props }) => {
  let els = ReactHtmlParser(text)

  if (accordion) {
    return (
      <div className={`${styles.element} ${className}`}>
        {els.shift()}
        <Accordion title={"Read More"} color={"transparent"} collapse={true}>
          {els}
        </Accordion>
      </div>
    )
  }

  return <div
    {...props}
    className={`${styles.element} ${className}`}
    dangerouslySetInnerHTML={{ __html: text }}
  />
};

export default RichText;
