import { idtype } from "querypieces";
import headingBody from "blocks/heading-body/query";
import image from "blocks/image/query";
import bodyCopy from "blocks/body-copy/query";

import { bodyCopy as bodyBlock, imageBlock} from "components/resource-builder/query"

export default `
...on pageBuilder_sideBySide_BlockType {
	${idtype}
	children {
		${image}
		${bodyCopy}
		${headingBody}
		${bodyBlock}
		${imageBlock}
	}
}
`