import React from "react"
import Link from "components/link"
import styles from "./resources.module.scss"
import Img from "components/image/image"
import SVG from "react-inlinesvg";
import play from "svgs/play.svg";

const Resources = ({resources}) => {
  const { colors, copy, image, links, title, hasVid } = resources[0]
  const playSvg = hasVid ? <SVG src={play} preProcessor={code => code.replace(/fill=".*?"/g, `fill="var(--${colors})"`)}  /> : null 

  return <div className={styles.element}>
    <div className={styles.image}>
      {playSvg}
      <Img {...image[0]} />
    </div>
    <div className={styles.content} style={{
      backgroundColor: `var(--${colors})`
    }}>
      <h5 className={styles.heading}>{title}</h5>
      <p className={styles.copy}>{copy}</p>
      <div className={styles.links}>
        {links.map(link => <Link key={link.id} {...link.item} />)}
      </div>
    </div>
  </div>
}

export default Resources