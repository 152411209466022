import React from "react"
import useUpdate from "./useUpdate";
import styles from "./update.module.scss"

import Img from "components/image"
import Link from "components/link";
import FooterCta from "components/footer-cta";
import ErrorPage from "components/error-page";
import Blocks from "blocks";

const Update = ({ match }) => {
  const { loading, error, data } = useUpdate({ slug: match.params.slug });

  if (loading) {
    return <ErrorPage type="loading" />
  }

  if (error && !data) {
    console.log(error)
    return <ErrorPage type="500" />
  }

  if (data && !data.entry) {
    return <ErrorPage type="404" />
  }

  return data ? <article className={styles.element}>
    {data.entry.typeHandle === "externalUpdate" ? 
      <div className={styles.headersplit}>
        {data.entry.image ? <Img {...data.entry.image[0]} /> : null }
        <header>
          <Header {...data.entry} />
        </header>
      </div>
      : <header className={styles.header}>
        <Header {...data.entry} />
      </header>
    }
    {data.entry.resourceBuilder ? <Blocks blocks={data.entry.resourceBuilder} entry={data.entry} /> : null}
    <FooterCta current={data.entry.id} />
  </article> : null
}

const Header = ({
  title,
  copy,
  publisherName,
  publishDate,
  externalUrl
}) => {
  let date = new Date(publishDate)

  return <>
    <h1 className={styles.heading}>{title}</h1>
    <h2 className={styles.subheading}>{copy}</h2>
    <div className={styles.meta}>
      <p>{publisherName}</p>
      <p>{`${date.getDate()}/${date.getDay()}/${date.getFullYear()}`}</p>
    </div>
    {externalUrl ? <Link url={externalUrl} text={"Visit resource"} className={`${styles.link} hover-yellow`} /> : null}
  </>
}

export default Update