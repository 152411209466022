import React, {useEffect,useRef,useContext} from "react"
import styles from "./alert-bar.module.scss"
import RichText from "components/rich-text";
import useAlertBar from "./useAlertBar";

import { NavContext } from "nav-context";

const AlertBar = () => {
  const {data} = useAlertBar();
  const navContext = useContext(NavContext)
  const ref = useRef();

  useEffect(() => {
    const calcHeight = () => {
      navContext.setBarHeight(ref.current ? ref.current.scrollHeight : 0);
    };

    calcHeight();

    window.addEventListener("resize", () => calcHeight());
    return () => window.removeEventListener("resize", calcHeight());
  }, [ref,navContext,data]);

  return data && data.alert && data.alert.isLive ?<aside className={styles.element} ref={ref}>
    <RichText text={data.alert.richText} />
  </aside> : <aside ref={ref} />
}

export default AlertBar