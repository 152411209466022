import React from "react"
import styles from "./bullet-list.module.scss"
import Heading from "blocks/heading"
import RichText from "components/rich-text"

const BulletList = ({heading,copy,colors,children,className=""}) => {
 return <div className={`${styles.element} ${className}`}>
    {heading ? <Heading heading={heading} colors={colors} className={className} /> : null}
    {copy ? <p className={styles.subheading}>{copy}</p> : null}
    <ul className={styles.list}>
      {children.map(item =>
        <li key={item.id} className={styles.item}>
          <RichText text={item.richText} />
        </li>
      )} 
    </ul>
 </div>
}

export default BulletList