import React from "react"
import { ImageFull as Image } from "components/image/image"
import RichText from "components/rich-text"
import Link from "components/link"
import styles from "./cta-block.module.scss"
// import { link } from "querypieces"

const CtaBlock = ({
  heading,
  copy,
  colors,
  linkTo,
  image
}) => {
  return <div className={styles.element}>
    {image ? <Image className={styles.image} {...image[0]} /> : null}
    <div className={styles.block}>
      <Link className={`${styles.link} hover-${colors}`} {...linkTo} />
      <div className={styles.content} style={{
        backgroundColor: `var(--${colors})`
      }}>
        <h3 className={styles.heading}>{heading}</h3>
        {copy ? <RichText className={styles.copy} text={copy} /> : null}
      </div>
    </div>
  </div>
}

export default CtaBlock