import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { idtype, image } from "querypieces";

const query = gql`
  query {
    locations: globalSet(handle: "locations") {
      ...on locations_GlobalSet {
        id
        name
        locations {
          ...on locations_location_BlockType {
            ${idtype}
            borough
            address
            contact
            website
            logo {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export default () => {
  return useQuery(query);
};
