import React, { useState, useContext } from "react"
import cookie from "react-cookies";
import styles from "./nav.module.scss"
import useNav from "./useNav";
import Link from "components/link";
import LanguageNav from "components/language-nav"
import { Link as RouterLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import logo from "svgs/logo.svg";
import { NavContext } from "nav-context";
import { useCheckMobileScreen } from "helpers";

const Nav = () => {
  const { data } = useNav();
  const mobile = useCheckMobileScreen();
  const [open, setOpen] = useState(false);
  const [langsOpen, setLangsOpen] = useState(false);
  const language = cookie.load("lang") ? cookie.load("lang") : "en"
  const navContext = useContext(NavContext)

  return <nav className={styles && styles.element ? styles.element : "nav-element"} style={{
    top: navContext.barHeight
  }}>
    <RouterLink to="/" className={styles && styles.logo ? styles.logo : "nav-logo"}>
      <SVG src={logo} />
    </RouterLink>
    {mobile ?
      <button className={`${styles.toggle} ${open ? "active" : ""}`} onClick={() => {
        setOpen(!open)
      }}></button>
      : null}
    <ul className={`${styles.list}${open && mobile ? " is-open" : ""}`}>
      {mobile ? <li className={styles.link} style={{
        backgroundColor: `var(--white)`
      }}>
        <RouterLink to="/" className={styles.home} onClick={() => setOpen(false)}>Home</RouterLink>
      </li> : null}
      {data ? data.nav.desktopNav.map(entry => (
        <li key={entry.id} className={styles.link} style={mobile ? {
          backgroundColor: `var(--${entry.item.element.color})`
        } : {}}>
          <Link
            className={`hover-${entry.item.element.color}`}
            onClick={() => {
              setOpen(false)
            }}
            {...entry.item}
            type="nav"
          />
        </li>
      )) : null}
    </ul>

    <button
      key={language}
      className={styles.button}
      onClick={() => {
        setLangsOpen(!langsOpen)
      }}>
      <span className={styles.link}>{language}</span>
    </button>
    <LanguageNav open={langsOpen} setOpen={setLangsOpen} />
  </nav>
}

export default Nav