import { idtype } from "querypieces";
import pageLink from "blocks/page-link/query";

export default `
...on pageBuilder_internalCtaBlock_BlockType {
	${idtype}
	children {
		${pageLink}
	}
}
`