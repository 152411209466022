import React from "react"
import { ImageOriginal, ImageFull } from "components/image/image";
import "./image.scss"

const Image = ({ image, colors, className = "" }) => {
  return image ? <figure className={`${className} frame-${colors}`}>
    {className.includes("accordion nested") ? <ImageOriginal {...image[0]} />
      : <ImageFull {...image[0]} />}
  </figure> : null
}

export default Image