import React, { useEffect, useState, useRef } from "react";
import { animated, useSpring } from "react-spring";
import styles from "./accordion.module.scss";

const Accordion = ({
  title,
  color,
  update,
  callBack,
  offset = 30,
  collapse = false,
  trigger = false,
  children
}) => {
  const [open, setOpen] = useState(trigger);
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const ref = useRef();

  useEffect(() => {
    setOpen(trigger)
  }, [trigger])

  useEffect(() => {
    const calcContentMaxHeight = () => {
      ref && ref.current && setContentMaxHeight(ref.current.scrollHeight);
    };

    calcContentMaxHeight();

    window.addEventListener("resize", () => calcContentMaxHeight());

    return () => window.removeEventListener("resize", calcContentMaxHeight());
  }, [ref, contentMaxHeight, update]);

  const { scY, y, ...props } = useSpring({
    scY: open ? -1 : 1,
    // y: open ? 0 : -contentMaxHeight,
    opacity: open ? 1 : 0,
    maxHeight: open ? `${contentMaxHeight + offset}px` : "0px",
    config: { duration: 300 }
  });

  return (<>
    <div
      className={styles.element}
      onClick={() => {
        setOpen(!open)
        if (typeof callBack === "function") callBack()
      }}
      style={{
        borderBottom: `var(--barheight) solid var(--${color})`,
        zIndex: 1
      }}>
      <Label collapse={collapse} open={open} title={title} />
    </div>
    <animated.div
      ref={ref}
      style={{
        overflow: "hidden",
        zIndex: 0,
        // transform: y.interpolate(y => `translateY(${y}px)`),
        ...props
      }}>
      <div pb={1} className={`${styles.children}`}>{children}</div>
    </animated.div>
  </>
  )
}

const Label = ({ collapse, open, title }) => {
  if (collapse) {
    return !open ? <p className={styles.subheading}>
      {title}
      <span>+</span>
    </p> : null
  }

  return <>
    <h3 className={styles.heading}>{title}</h3>
    <span>{open ? "–" : "+"}</span>
  </>
}

export default Accordion