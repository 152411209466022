import React from "react"
import Hero from "components/hero";
import styles from "./page.module.scss";

import FooterCta from "components/footer-cta";
import ErrorPage from "components/error-page";
import Blocks from "blocks";
import usePage from "./usePage";

const Page = ({ match }) => {
  const { loading, error, data } = usePage({ uri: match.url.replace("/", "") });

  if (loading) {
    return <ErrorPage type="loading" />
  }

  if ((error && !data) || !data) {
    console.log(error)
    return <ErrorPage type="500" />
  }

  if (data && !data.entry) {
    return <ErrorPage type="404" />
  }

  switch (data.entry.typeHandle) {
    case "page":
      return <article>
        <Hero
          heading={data.entry.heroHead}
          copy={data.entry.headSubhead}
          image={data.entry.heroImage}
        />
        <Blocks blocks={data.entry.pageBuilder} entry={data.entry} />
        <FooterCta current={data.entry.id} />
      </article>
    case "simplePage":
      return <article>
        <h1 className={styles.heading}>{data.entry.heading}</h1>
        {data.entry.pageBuilder ? <Blocks blocks={data.entry.pageBuilder} entry={data.entry} className={"simple"} /> : null}
        <FooterCta current={data.entry.id} />
      </article>
    default: 
      return null;
  }
}

export default Page