import { idtype } from "querypieces";
import heading from "blocks/heading/query";
import bodyCopy from "blocks/body-copy/query";
import image from "blocks/image/query";
import bulletList from "blocks/bullet-list/query";
import resources from "blocks/resources/query";
import news from "blocks/news/query";

export default `
...on pageBuilder_accordionContent_BlockType {
	${idtype}
	blocks: children {
		${heading}
		${bodyCopy}
		${image}
		${bulletList}
		${resources}
		${news}
	}
}
`